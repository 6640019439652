<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">New data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Name reviewer" v-model="form.name"
                        :danger="validation.hasError('form.name')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.name')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Title" v-model="form.title"
                        :danger="validation.hasError('form.title')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.title')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Description
                    </label>
                    <vs-textarea v-model="form.description" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="date" label="Date Experience" v-model="form.date_exp" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <div id="my-strictly-unique-vue-upload-multiple-image"
                        style="display: flex; justify-content: center;">
                        <vue-upload-multiple-image maxImage="4" @upload-success="uploadImageSuccess" @before-remove="beforeRemove"
                            @edit-image="editImage" :data-images="form.images" idUpload="myIdUpload"
                            editUpload="myIdEdit" dragText="Upload picture" browseText="Choose picture"
                            primaryText="Primary" popupText="Will use primary image" dropText="Upload here"
                            markIsPrimaryText="Mark as primary">
                        </vue-upload-multiple-image>
                    </div>
                </div>
            </div>

            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Simpan</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Kembali</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import VueUploadMultipleImage from 'vue-upload-multiple-image'
    import SimpleVueValidation from 'simple-vue-validator'
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                form: {
                    name: '',
                    title: '',
                    description: '',
                    date_exp: '',
                    images: []
                },
            }
        },
        components: {
            VueUploadMultipleImage
        },
        validators: {
            'form.name': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
            'form.title': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
        },

        methods: {
            back() {
                this.$router.go(-1)
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(async () => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Success!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                await this.clearForm();
                                this.validation.reset();
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    const postData = {
                        name: this.form.name,
                        title: this.form.title,
                        description: this.form.description,
                        date_exp: this.form.date_exp,
                        images: this.form.images
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/id/review',
                            method: 'POST',
                            data: postData
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            clearForm() {
                this.form.name = ""
                this.form.title = ""
                this.form.description = ""
                this.form.date_exp = ""
                this.form.images = []
            },
            uploadImageSuccess(formData, index, fileList) {
                this.form.images = fileList;
            },
            beforeRemove(index, done) {
                var r = confirm("remove image")
                if (r == true) {
                    done()
                }
            },
            editImage(formData, index, fileList) {
                this.form.images = fileList;
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>